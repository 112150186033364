<template>
  <div class="container">
    <div class="tk_text">退款申请<span>只能发起一次</span>，请谨慎操作!</div>
    <van-form @submit="onSubmit" ref="form">
      <van-field
        v-model="form.reason"
        placeholder="请选择退款原因"
        readonly
        clickable
        label="退款原因"
        :rules="[{ required: true, message: '' }]"
        @click="showPicker = true"
      />

      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="reasons"
          v-model="selectedReason"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
        v-model="form.note"
        placeholder="请输入备注"
        label="备注"
        type="textarea"
        maxlength="100"
        show-word-limit
        rows="2"
      />

      <van-cell
        title="订单总金额"
        :value="`￥${form.totalAmount}`"
        label-style="color: #c93736; font-weight: bold"
      />

      <van-field
        v-model="form.refundAmount"
        name="refundAmount"
        placeholder="请输入退款金额"
        type="number"
        label="退款金额"
        input-align="right"
        :rules="[{ required: true, message: '', validator: checkRefundAmount }]"
      />

      <van-button class="setBtn" block type="info" native-type="submit">
        确认退款
      </van-button>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        reason: "",
        note: "",
        totalAmount: 0, // demo number, please replace it.
        refundAmount: "",
      },
      reasons: [],
      selectedReason: "",
      showPicker: false,
    };
  },
  created() {
    this.form.totalAmount = this.$route.query.price;
    this.getOrderRefundReason();
  },
  methods: {
    //获取退款原因
    getOrderRefundReason() {
      this.$UtilsApi.order_refund_reason({}).then((res) => {
        if (res.data.code == 0) {
          this.reasons = res.data.data;
          console.log(res);
        }
      });
    },
    onConfirm(value) {
      this.form.reason = value;
      this.showPicker = false;
    },
    checkRefundAmount(value) {
      if (parseFloat(value) > parseFloat(this.form.totalAmount)) {
        this.form.refundAmount = this.form.totalAmount;
        return Promise.reject();
      }
      return Promise.resolve();
    },
    onSubmit() {
      let _this = this;
      this.$UtilsApi
        .refund({
          order_id: this.$route.query.id,
          refund_amount: this.form.refundAmount,
          reason: this.form.reason,
          note: this.form.note,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$dialog
              .alert({
                title: "申请成功!",
                message: "即将返回上一页",
              })
              .then(() => {
                // 返回上一页
                _this.$router.go(-1);
              });
          } else {
            this.$dialog
              .alert({
                title: "申请失败!",
                message: res.data.msg,
              })
              .then(() => {
                // 返回上一页
                _this.$router.go(-1);
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
}
.submit-button {
  margin: 20px;
}
.setBtn {
  width: 327rem;
  height: 55rem;
  background: #09ABFD;
  border-radius: 14rem;
  box-shadow: 0rem 8rem 16rem 0rem rgba(255, 128, 92, 0.08);
  margin: 120rem auto 0;
  font-size: 16rem;
  color: #ffffff;
  text-align: center;
  line-height: 55rem;
  border: none;
}
.van-cell {
  margin-top: 5rem;
}
.tk_text{
  height: 40rem;
  line-height: 40rem;
  text-align: center;
  font-size: 14rem;
}
.tk_text span{
  color: #ee0a24;
}
</style>